<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form
      ref="form"
      :inline="true"
      :model="formInline"
      small="size"
      label-width="110px"
    >
      <el-form-item label="处方订单号：">
        <el-input
          v-model.trim="formInline.prescriptionId"
          v-check-permission
          v-only-number
          placeholder="请输入处方订单号"
          maxlength="50"
        />
      </el-form-item>
      <el-form-item label="配送状态：">
        <el-select v-model="formInline.deliveryState">
          <el-option
            v-for="item in deliveryStateList"
            :key="item.value"
            :value="item.value"
            :label="item.text"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="订单支付时间：">
        <el-date-picker
          v-model="formInline.orderPayTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="queryData()"> 查询 </el-button>
        <el-button type="primary" @click="queryClear()"> 重置 </el-button>
      </el-form-item>
      <!-- </el-col>
      </el-row> -->
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column
        prop="prescriptionId"
        label="处方订单号"
        align="center"
      />
      <el-table-column prop="patientName" label="患者姓名" align="center" />
      <el-table-column prop="payTime" label="药品支付时间" align="center" />
      <el-table-column
        prop="medicalName"
        label="药品明细"
        align="center"
        show-overflow-tooltip
      />
      <el-table-column prop="saleMethod" label="售药方式" align="center">
        <template slot-scope="scope">
          <span>{{
            scope.row.saleMethod == '1' ? '到店自取' : '选择配送'
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="deliveryState" label="配送状态">
        <template slot-scope="scope">
          <span>
            {{ deliveryStateDIC[scope.row.deliveryState] }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间" align="center" />
      <el-table-column prop="drugstoreName" label="售药药店" align="center" />
      <el-table-column
        label="操作"
        show-overflow-tooltip
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="dataOperation(scope.row)">
            查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
// import { mapActions, mapState } from 'vuex';
import {
  deliveryStateList,
  deliveryStateDIC,
} from '@/utils/component/publicList';
import moment from 'moment';
export default {
  name: 'ServiceManageList',
  data() {
    return {
      deliveryStateList: deliveryStateList, // 配送状态列表
      deliveryStateDIC: deliveryStateDIC, // 配送状态字典
      loading: false,
      formInline: {
        prescriptionId: '',
        deliveryState: 'all',
        orderPayTime: '',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      orderListData: [], // 服务列表
    };
  },
  computed: {
    // ...mapState('basicDictionary', {
    //   occupationList: state => state.occupationList,
    //   professionalList:state => state.professionalList
    // }),
  },
  watch: {},
  mounted() {
    this.getDistributionList();
  },
  methods: {
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getDistributionList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getDistributionList();
    },
    // 重置
    queryClear() {
      this.formInline = {
        // 查询条件
        prescriptionId: '',
        deliveryState: 'all',
        orderPayTime: '',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getDistributionList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getDistributionList();
    },
    // 分页查询问诊类型列表
    getDistributionList() {
      const { formInline } = this;
      this.loading = true;
      const params = {
        prescriptionId: formInline.prescriptionId,
        deliveryState:
          formInline.deliveryState === 'all' ? '' : formInline.deliveryState,
        payStartTime:
          formInline.orderPayTime !== '' && formInline.orderPayTime !== null
            ? moment(this.formInline.orderPayTime[0]).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            : '',
        payEndTime:
          formInline.orderPayTime !== '' && formInline.orderPayTime !== null
            ? moment(this.formInline.orderPayTime[1]).format(
                'YYYY-MM-DD HH:mm:ss'
              )
            : '',
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      this.$api
        .distributionPageQuery(params)
        .then((res) => {
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
          this.loading = false;
        })
        .catch(() => {});
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 编辑
     * 2 - 删除
     */
    dataOperation(item) {
      this.$router.push({
        path: '/orderManage/circulationDetail',
        query: {
          detail: JSON.stringify(item),
        },
      });
    },
    // 确定取消健康咨询订单
    saveService() {
      const param = {
        ...this.form1,
        resourceNo: this.cancelOrderNo,
      };
      this.$api.cancelResource(param).then((res) => {
        if (res.code === 0) {
          this.$message.success('订单取消成功');
          this.refundDialogVisible = false;
        }
      });
    },
  },
};
</script>
<style lang="css">
.el-table th.gutter {
  display: table-cell !important;
}
</style>
<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
</style>
